
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NavigationButton',
  props: {
    to: {
      type: [String, Object],
      default: "#"
    }
  },
})

