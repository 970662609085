
import { defineComponent } from "vue";
import { getCurrentAge } from '@/composables/age';

export default defineComponent({
  name: "Home",
  setup() {
    return {
      currentAge: getCurrentAge(),
    }
  }
});
