import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "font-bold px-2 py-1 border-transparent border-b-4 focus:outline-none focus:text-gray-400 hover:border-white" }
const _hoisted_3 = { class: "font-bold px-2 py-1 border-transparent border-b-4 focus:outline-none focus:text-gray-400 hover:border-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.to.startsWith('http'))
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.to
      }, [
        _createElementVNode("button", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ], 8, _hoisted_1))
    : (_openBlock(), _createBlock(_component_router_link, {
        key: 1,
        to: _ctx.to
      }, {
        default: _withCtx(() => [
          _createElementVNode("button", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]),
        _: 3
      }, 8, ["to"]))
}