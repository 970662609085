
import NavigationBar from '@/components/NavigationBar.vue'

export default {
  components: {
    NavigationBar,
  },
  data: () => ({
    currentYear: new Date().getFullYear(),
  })
}
