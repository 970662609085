
import { defineComponent, Ref, ref, onMounted } from "vue";
import NavigationButton from "@/components/NavigationButton.vue";

export default defineComponent({
  name: "NavigationBar",
  components: {
    NavigationButton,
  },
  setup() {
    // false = "closed", true = "opened"
    const menuState: Ref<boolean> = ref(false);
    const navRef = ref(null)

    onMounted(() => {
      const el = navRef.value as unknown as Node
      // if clicked outside nav, close it
      document.addEventListener('click', event => {
        if(!el.contains(event.target as Node)) {
          menuState.value = false
        }
      })
    })

    return {
      menuState,
      navRef,
    };
  },
});
