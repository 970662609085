import { library } from "@fortawesome/fontawesome-svg-core";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faEnvelope,
  faExternalLinkAlt,
  faMailBulk,
  faTerminal,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faBars,
  faEnvelope,
  faExternalLinkAlt,
  faLinkedin,
  faGithub,
  faMailBulk,
  faTerminal,
  faTimes
);

export { FontAwesomeIcon };
